import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Familyday from "../components/FamilyDay"

const FamilydayPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title} keywords={fields.keywords} description={fields.description}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb 
            slug={fields.slug} 
            title={fields.title} 
            textShadow={true} 
            bgImageOverlay={true} 
            bgImage={bgImage} 
            bgMovePercent="0.2" 
            bgOffset="-80" />
          <Familyday data={fields} content={data.page.html} />
      </Layout>
    );
};
export default withI18next()(FamilydayPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      html
      frontmatter {
        slug
        title
        subtitle
        contentDescription
        keywords
        description
        questionTitle
        questionDescription
        questionPhone
        questionEmail
        blockquote        
        image {
          childImageSharp {
            fluid(maxWidth: 770, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "gatherings/familyday/familyday_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  }`