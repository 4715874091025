import React from "react";
import SmartForm from "../SmartForm"
import Heading from "../Heading"
import { useTranslation } from "react-i18next"
import './familyday.scss'
import SocialShare from "../SocialShare"
import QuestionBubble from "../QuestionBubble"
import Meta from "../Meta";

export default ({ data, content }) => {

    const { t } = useTranslation();

    return(
        <div id="familyday" className="blog-area blog-no-sidebar pt-100 pb-100">
            <Meta
                keywords={data.keywords}
                description={data.description}
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog-post-item ml-auto mr-auto">
                        <div className="row">
                            <div className="blog-wrapper blog-details">
                                <div className="blog-content">
                                    <Heading title={data.title} subtitle={data.subtitle} clasName="mt-50" />
                                    <div dangerouslySetInnerHTML={{__html: content}} />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-40">
                            <div className="col-md-6">
                                <blockquote className="mb-50">
                                    {data.blockquote}
                                </blockquote>
                            </div>
                            <div className="col-md-6">
                                <QuestionBubble 
                                    title={data.questionTitle} 
                                    description={data.questionDescription} 
                                    phone={data.questionPhone}  
                                    email={data.questionEmail} />
                            </div>
                        </div>
                        <div className="question-area">
                            <h3>{t('would_you_like_to_request_an_offer?')}</h3>
                            <SmartForm 
                                successTitle={t('your_offer_request_has_been_sent_successfully')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="familiday-form" 
                                formName="familiday" 
                                mailSubject="Tiliana családi nap ajánlatkérés"
                                stateProps={["name", "email", "phone", "message"]}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-input">
                                            <label>{t('my_name')}</label>
                                            <input name="name" type="text" required />
                                        </div>
                                    </div>    
                                </div>
                                <div className="row">
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_email')}</label>
                                            <input name="email" type="email" required />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="single-input">
                                            <label>{t('my_phone')}</label>
                                            <input name="phone" type="phone" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input">
                                            <label>{t('offer_request')}</label>
                                            <textarea className="form-control" name="message" required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input">
                                            <button className="sent-btn" type="submit">
                                                {t('request_offer')}
                                            </button>
                                        </div>
                                    </div>                                   
                                </div>
                            </SmartForm>
                        </div>
                        <div className="common-tag-and-next-prev mt-60">
                            <SocialShare title={data.title} hashtags="event,familyday"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}